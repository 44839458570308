import { Phone } from "@phosphor-icons/react";
import { useT } from "@/utils/translation";

export default function SupportInfoBar() {
  const { t } = useT();

  return (
    <div className="flex justify-center w-full text-sm p-4">
      <div className="md:max-w-lg w-full flex justify-center">
        <a
          className="flex items-center gap-1 underline"
          href="tel:0800 0670 000"
        >
          <Phone weight="bold" />
          {t("hotline")}
        </a>
      </div>
    </div>
  );
}
