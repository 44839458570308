import { toast } from "@/components/toasts/state";
import { datadogRum } from "@datadog/browser-rum";
import { useAuth } from "@/features/Auth/state";
import { useStation } from "@/features/Station/state";
import { CheckCircle } from "@phosphor-icons/react";
import { api } from "@/utils/api";
import { DD_ACTION, DD_ERROR, DD_GLOBAL_PROPERTY } from "@/utils/datadog";
import { create } from "zustand";
import { StartChargingResult } from "./PaymentForm/types";
import { i18n } from "next-i18next";
import {
  ChargingProcessStatus,
  ChargingProcessStatusResponse,
} from "@/utils/types/ChargingProcessStatus";
import { ChargingInfo } from "@/utils/types/ChargingInfo";
import { ConnectorStatus } from "@/utils/types/ConnectorStatus.enum";
import { parseClientError } from "@/utils/errors";
import { ChargingProcess } from "@/utils/types/ChargingProcess";

export interface ChargingProcessState {
  setChargingProcessState: (state: Partial<ChargingProcessState>) => void;
  email: string;
  emailError: boolean;
  amount: number;
  hasRequestedStartCharging: boolean;
  shouldStartCharging: boolean;
  hasFinishedCharging: boolean;
  chargingProcessId: string | null;
  chargingInfo: ChargingInfo | null;
  paymentIntentId: string | null;
  paymentMethodId: string | null;
  stripeClientSecret: string | null;
  startChargingPollInterval: NodeJS.Timeout | null;
  // starting charging
  startCharging: (
    paymentMethodId?: string
  ) => Promise<StartChargingResult | undefined | void>;
  onStartChargingSuccess: (chargingProcessId: string) => void;
  onStartChargingError: (response?: any) => void;
  onStartChargingPolling: () => void;
  startChargingPollingHandler: () => void;
  stopStartChargingPolling: () => void;
  // during charging
  chargingInfoPollInterval: NodeJS.Timeout | null;
  getConnectorStatus: () => Promise<ConnectorStatus | null>;
  getChargingInfo: () => Promise<ChargingInfo | null>;
  getChargingProcess: () => Promise<ChargingProcess | null>;
  dismissChargingInfo: () => void;
  chargingInfoPollingHandler: () => void;
  stopChargingInfoPolling: () => void;
  processChargingProcess: () => void;
  // stop charging
  isStoppingCharging: boolean;
  stopCharging: () => Promise<void>;
}

export const useChargingProcess = create<ChargingProcessState>()(
  (set, get) => ({
    setChargingProcessState: set,
    email: "",
    emailError: false,
    amount: 50,
    shouldStartCharging: false,
    hasRequestedStartCharging: false,
    hasFinishedCharging: false,
    chargingProcessId: null,
    chargingInfo: null,
    startChargingPollInterval: null,
    paymentIntentId: null,
    paymentMethodId: null,
    stripeClientSecret: null,
    startCharging: async () => {
      const { paymentIntentId, paymentMethodId, amount, email } = get();
      const { userIdentification } = useAuth.getState();
      const { station, connector } = useStation.getState();

      set({ hasRequestedStartCharging: true });
      datadogRum.setGlobalContextProperty(
        DD_GLOBAL_PROPERTY.PAYMENT_INTENT_ID,
        paymentIntentId
      );

      try {
        const { data: chargingData } = await api.post(
          "/chargingProcess/start",
          {
            userIdentification,
            chargingStationId: station?.id,
            connectorId: connector?.connectorId,
            paymentIntentId,
            amount,
            email,
            paymentMethodId,
          }
        );

        if (chargingData?.chargingProcessId) {
          get().onStartChargingSuccess(chargingData.chargingProcessId);
        } else if (
          chargingData?.chargingProcessResult === "EXTENDED_AUTH_REQUIRED"
        ) {
          return StartChargingResult.EXTENDED_AUTH_REQUIRED;
        } else if (
          chargingData?.chargingProcessResult === "CHARGING_STATION_REJECTED"
        ) {
          toast.error({
            message: i18n?.t("error.charging"),
          });
          set({ hasRequestedStartCharging: false });
          return StartChargingResult.NOT_STARTED;
        } else {
          get().onStartChargingPolling();
        }
      } catch (e: any) {
        return get().onStartChargingError(e?.response);
      } finally {
        set({ shouldStartCharging: false });
      }
    },
    onStartChargingSuccess: (chargingProcessId) => {
      const { station, connector } = useStation.getState();
      const isFree = Boolean(connector?.priceInfo.isFree);

      datadogRum.addAction(DD_ACTION.START_CHARGING, {
        stationId: station?.id,
        connectorId: connector?.connectorId,
        chargingProcessId,
      });

      set({
        chargingProcessId,
        paymentIntentId: null,
        stripeClientSecret: null,
        paymentMethodId: null,
        hasRequestedStartCharging: false,
      });

      datadogRum.setGlobalContextProperty(
        DD_GLOBAL_PROPERTY.CHARGING_PROCESS_ID,
        chargingProcessId
      );

      toast.success({
        message: isFree
          ? i18n?.t("feedbackAfterStartingFree")
          : i18n?.t("feedbackAfterStarting"),
        icon: CheckCircle,
      });
    },
    onStartChargingError: (response) => {
      useStation.getState().getStationData();

      switch (response?.status) {
        case 400: {
          if (response?.data?.code === "payment_intent_in_use") {
            return StartChargingResult.PAYMENT_INTENT_IN_USE;
          } else {
            toast.error({
              message: i18n?.t("error.terminalError", {
                error_code: response?.data?.code,
              }),
            });
          }
          break;
        }

        case 402: {
          switch (response?.data?.code) {
            case "card_declined": {
              switch (response?.data?.errorReason) {
                case "insufficient_funds":
                  toast.error({
                    message: i18n?.t("error.insufficientFunds"),
                  });
                  break;

                case "card_velocity_exceeded":
                  toast.error({
                    message: i18n?.t("error.cardVelocityExceeded"),
                  });
                  break;

                default:
                  toast.error({
                    message: i18n?.t("error.cardDeclined"),
                  });
                  break;
              }
              break;
            }

            case "expired_card":
              toast.error({
                message: i18n?.t("error.expiredCard"),
              });
              break;

            case "incorrect_cvc":
              toast.error({
                message: i18n?.t("error.incorrectCVC"),
              });
              break;

            case "processing_error":
              toast.error({
                message: i18n?.t("error.payment"),
              });
              break;

            default:
              toast.error({
                message: i18n?.t("error.payment"),
              });
              break;
          }

          break;
        }

        case 403: {
          toast.error({
            message: i18n?.t("error.identificationForbidden"),
          });
          break;
        }

        case 404: {
          toast.error({
            message: i18n?.t("error.charging"),
          });
          break;
        }

        case 409: {
          toast.error({
            message: i18n?.t("error.startedByDifferentUser"),
          });
          break;
        }

        case 502:
        case 504: {
          get().onStartChargingPolling();
          return;
        }

        default: {
          toast.error({
            message: i18n?.t("error.charging"),
          });
          break;
        }
      }

      set({ hasRequestedStartCharging: false });

      parseClientError({ response }, "START_CHARGING_ERROR");
    },
    onStartChargingPolling: () => {
      const { station, connector } = useStation.getState();

      if (connector?.priceInfo.isFree) {
        get().onStartChargingError();
        return;
      }

      const paymentIntentId = get().paymentIntentId;

      datadogRum.addAction(DD_ACTION.START_CHARGING_TIMEOUT, {
        stationId: station?.id,
        connectorId: connector?.connectorId,
        paymentIntentId,
      });

      const timeoutInterval = setInterval(
        get().startChargingPollingHandler,
        3000
      );
      set({ startChargingPollInterval: timeoutInterval });
    },
    startChargingPollingHandler: async () => {
      const { station, connector } = useStation.getState();
      const paymentIntentId = get().paymentIntentId;
      const isFree = Boolean(connector?.priceInfo.isFree);

      try {
        const { data } = await api.get<ChargingProcessStatusResponse>(
          "/chargingProcess/status",
          {
            params: { paymentIntentId },
            headers: { "Cache-Control": "no-cache" },
          }
        );

        switch (data.chargingProcessStatus) {
          case ChargingProcessStatus.CHARGING_PROCESS_STARTED: {
            get().onStartChargingSuccess(data.chargingProcessId.toString());
            get().stopStartChargingPolling();

            toast.success({
              message: isFree
                ? i18n?.t("feedbackAfterStartingFree")
                : i18n?.t("feedbackAfterStarting"),
              icon: CheckCircle,
            });

            datadogRum.addAction(DD_ACTION.START_CHARGING, {
              stationId: station?.id,
              connectorId: connector?.connectorId,
              chargingProcessId: data.chargingProcessId,
            });
            datadogRum.setGlobalContextProperty(
              DD_GLOBAL_PROPERTY.CHARGING_PROCESS_ID,
              data.chargingProcessId
            );
            break;
          }

          case ChargingProcessStatus.PAYMENT_INTENT_INVALID:
          case ChargingProcessStatus.START_EXPIRED: {
            set({
              paymentIntentId: null,
              stripeClientSecret: null,
              paymentMethodId: null,
              hasRequestedStartCharging: false,
            });
            get().stopStartChargingPolling();
            toast.error({
              message: i18n?.t("error.charging"),
            });
            break;
          }

          case ChargingProcessStatus.PAYMENT_INTENT_VALID: {
            set({ shouldStartCharging: true });
            get().stopStartChargingPolling();
            break;
          }

          default:
            break;
        }
      } catch (e: any) {
        parseClientError(e, "GET_PAYMENT_INTENT_STATUS");

        datadogRum.addError(DD_ERROR.GET_PAYMENT_INTENT_STATUS, {
          data: e?.response?.data,
          status: e?.response?.status,
        });
      }
    },
    stopStartChargingPolling: () => {
      const intervalIdentifier = get().startChargingPollInterval;

      if (intervalIdentifier) {
        clearInterval(intervalIdentifier);
        set({ startChargingPollInterval: null });
      }
    },
    // during charging
    chargingInfoPollInterval: null,
    processChargingProcess: () => {
      const chargingProcessId = get().chargingProcessId;

      if (chargingProcessId) {
        const handler = get().chargingInfoPollingHandler;
        handler();
        const chargingInfoPollInterval = setInterval(handler, 10 * 1000);
        set({ chargingInfoPollInterval });
      }
    },
    getConnectorStatus: async () => {
      const { station, connector } = useStation.getState();

      try {
        const { data } = await api.get<ConnectorStatus>("/station/getStatus", {
          params: {
            stationId: station?.id,
            connectorId: connector?.connectorId,
          },
          headers: { "Cache-Control": "no-cache" },
        });

        return data;
      } catch (e: any) {
        parseClientError(e, "CHARGING_STATION_STATUS_ERROR");
        return null;
      }
    },
    getChargingInfo: async () => {
      const chargingProcessId = get().chargingProcessId;

      try {
        const { data } = await api.get<ChargingInfo>("/chargingProcess/info", {
          params: { chargingProcessId },
          headers: { "Cache-Control": "no-cache" },
        });

        return data;
      } catch (e) {
        parseClientError(e, "CHARGING_PROCESS_INFO");
        console.dir(e);
        return null;
      }
    },
    getChargingProcess: async () => {
      const chargingProcessId = get().chargingProcessId;

      try {
        const { data: chargingProcess } = await api.get<ChargingProcess>(
          "/chargingProcess/data",
          { params: { chargingProcessId } }
        );

        return chargingProcess;
      } catch (e) {
        parseClientError(e, "CHARGING_PROCESS_COSTS");
        return null;
      }
    },
    chargingInfoPollingHandler: async () => {
      const hasRequestedStartCharging = get().hasRequestedStartCharging;

      try {
        let [status, chargingInfo] = await Promise.all([
          get().getConnectorStatus(),
          get().getChargingInfo(),
        ]);

        if (hasRequestedStartCharging && status === ConnectorStatus.CHARGING) {
          set({ hasRequestedStartCharging: false });
        }

        if (
          ![ConnectorStatus.CHARGING, ConnectorStatus.FINISHING].includes(
            status as ConnectorStatus
          ) &&
          !hasRequestedStartCharging
        ) {
          setTimeout(async () => {
            const chargingProcess = await get().getChargingProcess();

            if (chargingProcess !== null) {
              chargingInfo = {
                ...chargingInfo,
                energyConsumption: chargingInfo?.energyConsumption || 0,
                chargingProcess,
              };
            }
            set({ hasFinishedCharging: true, isStoppingCharging: false });
            get().stopChargingInfoPolling();
            set({ chargingInfo });
          }, 3000);
        }

        set({ chargingInfo });
        useStation.getState().setConnectorStatus(status);
      } catch (e) {
        set({ isStoppingCharging: false });
      }
    },
    stopChargingInfoPolling: () => {
      const intervalIdentifier = get().chargingInfoPollInterval;

      if (intervalIdentifier) {
        clearInterval(intervalIdentifier);
        set({ chargingInfoPollInterval: null });
      }
    },
    dismissChargingInfo: () => {
      set({
        chargingProcessId: null,
        hasFinishedCharging: false,
        chargingInfo: null,
        paymentIntentId: null,
        stripeClientSecret: null,
        paymentMethodId: null,
      });
    },
    // stop charging
    isStoppingCharging: false,
    stopCharging: async () => {
      const { station, connector } = useStation.getState();
      const chargingProcessId = get().chargingProcessId;

      set({ isStoppingCharging: true });
      try {
        await api.post("/chargingProcess/stop", {
          chargingStationId: station?.id,
          connectorId: connector?.connectorId,
          chargingProcessId,
        });
        datadogRum.addAction(DD_ACTION.STOP_CHARGING, {
          stationId: station?.id,
          connectorId: connector?.connectorId,
          chargingProcessId,
        });
      } catch (e) {
        parseClientError(e, "STOP_CHARGING");
        set({ isStoppingCharging: false });
      }
    },
  })
);
