import "@/styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { Inter } from "next/font/google";
import localFont from "next/font/local";
import { appWithTranslation } from "next-i18next";
import StorageStateSync from "@/utils/StorageStateSync";
import Auth from "@/features/Auth/Auth";
import SupportInfoBar from "@/components/SupportInfoBar";
import Footer from "@/components/Footer";
import { useT } from "@/utils/translation";

export const inter = Inter({ subsets: ["latin"], variable: "--font-inter" });
export const officina = localFont({
  src: "../styles/fonts/officina.woff2",
  variable: "--font-officina",
});

function App({ Component, pageProps }: AppProps) {
  const { t } = useT();

  return (
    <main
      className={`h-screen w-full max-h-screen max-w-full ${officina.variable} ${inter.variable} font-sans flex flex-col bg-sand`}
    >
      <Head>
        <title>{t("pageTitle")}</title>
        <link href="/favicon.svg" rel="icon" type="image/svg+xml" />
      </Head>

      <SupportInfoBar />

      <StorageStateSync>
        <Auth>
          <Component {...pageProps} />
        </Auth>
      </StorageStateSync>

      <Footer />
    </main>
  );
}
export default appWithTranslation(App);
